import React from 'react'

export default ({ width = 50, height = 50, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height} viewBox="0 0 100 100">
      <path
        id="i-lrg"
        d="M89.5 70.4V10.3c0-.7-.3-1.3-.9-1.7s-1.3-.5-1.9-.3l-44 15.4c-.8.3-1.4 1.1-1.4 2v42.7c-4.1-2.5-10.6-2.9-17.3-.4-4.4 1.6-8.1 4.1-10.6 7.2-2.7 3.3-3.6 6.9-2.4 10s4.1 5.3 8.3 6.1c1.2.2 2.4.3 3.7.3 2.9 0 6-.6 9-1.7 8.1-3 13.4-8.9 13.5-14.4V27.2l39.9-13.9V64c-4.1-2.5-10.6-2.8-17.2-.4-4.4 1.6-8.1 4.1-10.6 7.1-2.7 3.3-3.6 6.9-2.4 10 1.1 3.1 4.1 5.3 8.3 6.1 1.2.2 2.4.3 3.7.3 2.9 0 6-.6 9-1.7 4.4-1.6 8.1-4.1 10.6-7.1 1.9-2.5 2.9-5.3 2.7-7.9zM30.5 86c-3.7 1.3-7.4 1.7-10.5 1.1-1.6-.3-4.3-1.2-5.2-3.4-.8-2.3.7-4.7 1.7-6 2-2.4 5.1-4.5 8.8-5.9 2.6-1 5.3-1.4 7.6-1.4 4 0 7.1 1.3 8 3.7.2.4.2.9.2 1.3.2 3.8-4.3 8.3-10.6 10.6zm44.1-4.4c-3.7 1.3-7.4 1.7-10.5 1.1-1.6-.3-4.3-1.2-5.2-3.4-.8-2.3.7-4.7 1.7-5.9 2-2.4 5.1-4.5 8.7-5.9 2.6-1 5.3-1.4 7.6-1.4 4 0 7.1 1.3 8 3.7.1.3.1.5.2.8v1.1c-.2 1.6-1.2 3.1-1.9 4-1.8 2.5-4.9 4.6-8.6 5.9z"
      />
    </svg>
  )
}
