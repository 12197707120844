import React from 'react'

export default ({ width = 50, height = 50, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height} viewBox="0 0 100 100">
      <path
        id="i-lrg_1_"
        d="M64.6 79.2H16.7c-4.6 0-8.3-3.7-8.3-8.3V29.2c0-4.6 3.7-8.3 8.3-8.3h47.9c4.6 0 8.3 3.7 8.3 8.3v9.7l10.8-10.4c.8-.8 2-1.3 3.3-1.3 2.6 0 4.7 2.1 4.7 4.7v36.4c0 1.3-.5 2.4-1.4 3.3-1.8 1.8-4.8 1.8-6.6 0L72.9 61.1v9.7c0 4.6-3.7 8.4-8.3 8.4zM16.7 25c-2.3 0-4.2 1.9-4.2 4.2v41.7c0 2.3 1.9 4.2 4.2 4.2h47.9c2.3 0 4.2-1.9 4.2-4.2V56.2c0-.8.5-1.6 1.3-1.9.8-.3 1.7-.2 2.3.4l14.3 13.8c.2.2.3.2.4.2s.2 0 .4-.2c.1-.1.2-.2.2-.4V31.8c0-.3-.3-.6-.5-.5-.1 0-.3.1-.4.2L72.3 45.3c-.6.6-1.5.7-2.3.4s-1.3-1.1-1.3-1.9V29.2c0-2.3-1.9-4.2-4.2-4.2H16.7z"
      />
    </svg>
  )
}
