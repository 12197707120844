import React from 'react'

export default ({ width = 50, height = 50, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height} viewBox="0 0 100 100">
      <path
        id="i-lrg_1_"
        d="M89 49.1c-1.1-1.7-2.3-3.2-3.9-4.7-2.7-2.2-5.8-3.9-9.1-5.2.5-5.4-2.8-10.1-2.9-10.2-2-3.2-4.7-6-7.8-8.1-3.4-2.3-7.1-4-11.1-5-4.3-1.1-8.8-1.6-13.2-1.3-4.5.3-8.8 1.2-13 2.9-3.8 1.5-7.4 3.6-10.5 6.3-2.9 2.5-5.3 5.5-7 9C9 36.2 8.2 40 8.4 43.7c.1 2.4.6 4.7 1.5 6.9s2.1 4.2 3.5 6.1c1.5 1.9 3.3 3.6 5.2 5 1.5 1.1 3.8 2.2 6.3 3.4-2.9 5-7.9 8-7.9 8-.8.5-1.2 1.4-1 2.3.2.9 1 1.5 1.9 1.6 1 .1 2 .1 3 .1 2 0 3.9-.2 5.9-.5 2.1-.3 4.1-.9 6.1-1.6.1 0 2.4-.9 8.4-4.2 1.4 1.9 3.2 3.6 5.2 5 2.7 1.8 5.6 3.2 8.8 4 3.3.9 6.7 1.2 10 1 1 .7 2.1 1.3 3.2 1.8 1.6.8 3.2 1.4 5 1.9 2 .5 4 .8 6 .9h1.3c1.9 0 3.9-.2 5.8-.6.8-.2 1.5-.9 1.6-1.7.1-.9-.3-1.7-1-2.1-2-1.2-4.8-3.4-5.7-5.3 1.3-.8 2.4-1.8 3.5-2.8 1.4-1.3 2.7-2.8 3.7-4.5 1-1.6 1.8-3.3 2.3-5.2 1.4-4.7.6-9.9-2-14.1zm-57.6 22c-1.7.6-3.5 1.1-5.4 1.4-.7.1-1.3.2-2 .3 2-2 4.2-4.6 5.6-7.9.4-1.1-.1-2.3-1.2-2.8-2-.9-5.8-2.5-7.4-3.7-1.7-1.2-3.1-2.7-4.4-4.3-1.2-1.5-2.2-3.2-2.9-5s-1.1-3.7-1.2-5.6c-.1-3 .5-6.1 1.8-8.8 1.4-2.9 3.4-5.5 5.9-7.7 2.8-2.4 5.9-4.3 9.3-5.6 3.7-1.5 7.7-2.3 11.7-2.6 4-.3 8 .1 11.9 1.2 3.5.9 6.8 2.4 9.8 4.4 2.7 1.8 4.9 4.1 6.7 7 0 0 2.2 3.3 2.2 6.8-2.8-.5-5.6-.7-8.4-.6-3.5.2-7 1-10.3 2.3-3.1 1.2-5.9 2.9-8.3 5-2.3 2-4.2 4.4-5.6 7.2-1.3 2.7-1.9 5.7-1.8 8.7.1 2.2.6 4.4 1.5 6.4-5.3 3.1-7.4 3.9-7.5 3.9zm55.6-9c-.4 1.4-1 2.8-1.8 4.1-.9 1.3-1.9 2.6-3.1 3.7-1.3 1.2-2.6 2.2-4.1 3.1-.7.4-1.1 1.1-1 1.9.1 2.5 1.8 4.7 3.6 6.4h-.9c-1.7-.1-3.5-.3-5.1-.8-1.5-.4-2.9-.9-4.2-1.6-1.1-.5-2.2-1.2-3.2-1.9-.3-.2-.8-.4-1.2-.4h-.5c-3.1.2-6.2-.1-9.1-.9-2.7-.7-5.2-1.8-7.5-3.4-2-1.4-3.8-3.2-5.1-5.2-1.2-2-1.9-4.2-2-6.5s.4-4.6 1.4-6.7c1.1-2.3 2.6-4.2 4.5-5.9 2.1-1.8 4.5-3.3 7.1-4.3 2.9-1.1 5.9-1.8 9-2s6.2.1 9.1.9l.8.2c.1 0 .2.1.2.1 3.1 1.1 6 2.7 8.5 4.7 1.2 1.1 2.2 2.3 3.1 3.7 2 3.2 2.5 7.2 1.5 10.8z"
      />
    </svg>
  )
}
