import React from 'react'

const SectionTitle = ({ title = '', ...rest }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">{title}</h2>
    </div>
  )
}

export default SectionTitle
