import React, { useState } from 'react'
import differenceInCalendarYears from 'date-fns/differenceInYears'
import isDate from 'date-fns/isDate'
import format from 'date-fns/format'

const BirthdayInput = ({ handleBirthdayChange, setErrors }) => {
  const [birthdayDay, setBirthdayDay] = useState('')
  const [birthdayMonth, setBirthdayMonth] = useState('')
  const [birthdayYear, setBirthdayYear] = useState('')
  const [error, setError] = useState('')

  const handleValidation = () => {
    const handleError = (error) => {
      setError(error)
      if (error) {
        setErrors({ birthday: error })
      } else {
        setErrors(null)
      }
    }

    if (!birthdayDay || !birthdayMonth || !birthdayYear) return

    const checkNumber = (val) => !Number.isNaN(Number.parseInt(val, 10))
    if (!checkNumber(birthdayDay) || !checkNumber(birthdayMonth) || !checkNumber(birthdayYear))
      handleError('Bitte überpfrüfen Sie das angegebe Datum!')

    const birthdayDate = new Date(birthdayYear, Number.parseInt(birthdayMonth - 1), birthdayDay)
    const isValidDate = !isDate(birthdayDate) || birthdayYear < 1900
    if (isValidDate) return handleError('Bitte überpfrüfen Sie das angegebe Datum!')

    const yearsDifference = differenceInCalendarYears(new Date(), new Date(birthdayDate))

    const isEligible = yearsDifference >= 18 && yearsDifference < 28
    if (!isEligible) return handleError('Das Angebot gilt nur für jungle Leute unter 28 Jahren!')

    handleError(null)
    handleBirthdayChange(format(birthdayDate, 'yyyy-MM-dd'))
  }
  return (
    <div className="w-full flex flex-wrap mb-3">
      <div className="w-full px-3">
        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
          Geburtsdatum
        </label>
      </div>
      <div className="w-1/3 px-3">
        <input
          required
          className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
          id="birthdayDay"
          type="number"
          data-name="birthdayDay"
          name="birthdayDay"
          placeholder="TT"
          onChange={(e) => setBirthdayDay(e.target.value)}
          onBlur={() => handleValidation()}
          value={birthdayDay}
        />
      </div>
      <div className="w-1/3 px-3">
        <input
          required
          className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
          id="birthdayMonth"
          type="number"
          data-name="birthdayMonth"
          name="birthdayMonth"
          placeholder="MM"
          onChange={(e) => setBirthdayMonth(e.target.value)}
          onBlur={() => handleValidation()}
          value={birthdayMonth}
        />
      </div>
      <div className="w-1/3 px-3">
        <input
          required
          className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
          id="birth-year"
          type="number"
          data-name="birthdayYear"
          name="birthdayYear"
          placeholder="JJJJ"
          onChange={(e) => setBirthdayYear(e.target.value)}
          onBlur={() => handleValidation()}
        />
      </div>
      {error && <p className="text-primary px-3 mt-2">{error}</p>}
    </div>
  )
}

export default BirthdayInput
