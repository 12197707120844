import React, { useState } from 'react'
import PassSection from './PassSection'
import AddressForm from './AddressForm'
import RateOverview from './RateOverview'
import usePassData from '../../hooks/usePassData'
import useTarif from './useTarif'

const MainSection = () => {
  const passData = usePassData()
  const tarif = useTarif()
  const [pass, setPass] = useState(passData[passData.passKeyArray[0]])

  const handleOnPassClick = (pass) => {
    setPass({ ...pass, active: true })
  }

  return (
    <div className={`mt-5 container mx-auto px-6 md:px-0`}>
      <h1 className="md:text-5xl text-4xl mb-8 md:mb-0">Deine Bestellung</h1>
      <div className="flex flex-wrap mb-4 flex-col-reverse md:flex-no-wrap md:flex-row">
        <div className="w-full md:w-4/6 h-auto">
          <PassSection handleOnClick={handleOnPassClick} currentPass={pass} passData={passData} />
          <AddressForm rate={tarif} currentPass={pass} />
        </div>
        <div className="w-full md:w-2/6 h-full mb-8 md:mb-0">
          <RateOverview currentPass={pass} />
        </div>
      </div>
    </div>
  )
}

export default MainSection
