import React from 'react'

export default ({ width = 50, height = 50, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height} viewBox="0 0 100 100">
      <path
        id="i-lrg"
        d="M78.7 51c3.6-2.5 5.9-6.6 5.9-11.3C84.7 32.1 78.5 26 71 26c-7.6 0-13.7 6.2-13.7 13.7 0 4.7 2.4 8.8 5.9 11.3-3.2 1.5-6.1 4-8.3 7.4-2.8-3.3-6.1-5.8-9.8-7.3 5.6-3.1 9.4-9.2 9.4-16 0-10.1-8.2-18.4-18.4-18.4S17.6 24.9 17.6 35c0 6.9 3.8 12.9 9.4 16-9.4 4-16.4 14.1-18.6 28-.2 1.1.6 2.2 1.7 2.4 1.1.2 2.2-.6 2.4-1.7 2.6-16 11.8-26.2 23.5-26.2s20.9 10.3 23.6 26.2c.2 1 1.1 1.7 2.1 1.7h.3c1.1-.2 1.9-1.3 1.7-2.4-1.1-6.6-3.2-12.3-6.2-16.9 3.1-5.5 7.9-8.6 13.4-8.6 8.3 0 14.7 6.8 16.6 17.6.2 1 1.1 1.7 2.1 1.7h.4c1.1-.2 1.9-1.3 1.7-2.4C90 61 85.2 54.1 78.7 51zM21.8 35c0-7.8 6.4-14.2 14.2-14.2S50.2 27.2 50.2 35 43.8 49.2 36 49.2c-7.8.1-14.2-6.3-14.2-14.2zm39.6 4.7c0-5.3 4.3-9.6 9.6-9.6s9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6z"
      ></path>
    </svg>
  )
}
