import { navigate } from 'gatsby'

const deNumberFormat = new Intl.NumberFormat('de-DE')

const useTarif = () => {
  if (typeof window === `undefined`) return

  const hasTarif = window.history.state?.tarif ?? localStorage.getItem('tarif')
  if (hasTarif) {
    const tarif = window.history.state?.tarif ?? JSON.parse(localStorage.getItem('tarif'))

    const volume = deNumberFormat.format(tarif.new_volume ?? tarif.volume)
    return {
      ...tarif,
      volume,
      price: deNumberFormat.format(tarif.price),
      new_price: deNumberFormat.format(tarif.new_price),
      connection_price: deNumberFormat.format(tarif.connection_price),
    }
  } else {
    navigate('/')
  }
}

export default useTarif
