import React from 'react'
import {Link} from 'gatsby'
import SectionTitle from './SectionTitle'
import useTarif from './useTarif'
import CheckMark from '../icons/CheckMark'
import ChevronDown from '../icons/ChevronDown'

const RateOverview = ({ currentPass = {} }) => {
  const tarif = useTarif()
  return !tarif ? (
    <div />
  ) : (
    <div className="md:ml-16 ml-0 border border-gray-400 p-2 h-full">
      <div className="mb-3 px-3 justify-items-stretch grid grid-cols-2">
        <SectionTitle title="Deine Auswahl" />
        <Link className="text-xs text-right items-center flex underline hover:text-primary" to={`/#tarif`}>Auswahl ändern</Link>
      </div>
      <div className="w-full bg-gray-200 mb-3">
        <div className="border-b border-gray-600">
          <div className="p-3">
            <h3 className="font-bold">Monatlich</h3>
          </div>
        </div>
        <div className="p-3 flex w-full">
          <div className="w-4/6">
            <div className=" font-bold text-primary">
              {tarif.title} mit {tarif.volume} GB
            </div>
            <div className="text-xs text-gray-700">
              inkl. {currentPass.name} <br />
              {tarif.new_price && (
                  <span>
                  ab dem 13. Monat <span className="font-bold">{tarif.price}€</span>
                </span>
              )}
            </div>
          </div>
          <div className="w-2/6 text-right">
            <span className="text-gray-800">{tarif.new_price}€</span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-200 mb-3">
        <div className="border-b border-gray-600">
          <div className="p-3">
            <h3 className="font-bold">Einmalig</h3>
          </div>
        </div>
        <div className="p-3 flex w-full">
          <div className="w-4/6">
            <div className="text-gray-700">Anschlusspreis</div>
          </div>
          <div className="w-2/6 text-right">
            <span className="text-gray-800">{tarif.connection_price}€</span>
          </div>
        </div>
      </div>
      <div className="w-full bg-green-200 mb-3">
        <div className="border-b border-gray-600">
          <div className="p-3">
            <h3 className="font-bold">Deine Vorteile</h3>
          </div>
        </div>
        <div className="p-3 flex w-full">
          <table className="table-auto border-0 w-full">
            <tbody>
              <tr>
                <td className="px-4 py-2">
                  nextbike Gutschein <br />
                  <span className="text-xs text-gray-600">
                    im Wert von {tarif.promotion_voucher_value}€
                  </span>
                </td>
                <td className="px-4 py-2">
                  <div className="rounded-full h-5 w-5 flex items-center justify-center">
                    <CheckMark />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">Gaming Pass</td>
                <td className="px-4 py-2">
                  <div className="rounded-full h-5 w-5 flex items-center justify-center">
                    <CheckMark />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">Highspeed-Internet</td>
                <td className="px-4 py-2">
                  <div className="rounded-full h-5 w-5 flex items-center justify-center">
                    <CheckMark />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">Telefon- und SMS-Flat</td>
                <td className="px-4 py-2">
                  <div className="rounded-full h-5 w-5 flex items-center justify-center">
                    <CheckMark />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full mb-3">
        <span className="text-sm px-3">Mindestvertragslaufzeit 24 Monate</span>
      </div>
    </div>
  )
}

export default RateOverview
