import React from 'react'
import SocialPass from '../icons/SocialPass'
import ChatPass from '../icons/ChatPass'
import MusicPass from '../icons/MusicPass'
import VideoPass from '../icons/VideoPass'

const PassSection = ({
  currentPass,
  title,
  handleOnClick,
  passData: { passKeyArray, ...passData },
}) => {
  const getIcon = (passType) => {
    if (passType === 'social') return <SocialPass />
    if (passType === 'chat') return <ChatPass />
    if (passType === 'music') return <MusicPass />
    if (passType === 'video') return <VideoPass />
  }

  const isFirstIndex = (index) => {
    return index === 0
  }

  const isLastIndex = (index) => {
    const passLength = passData.length - 1

    return index === passLength
  }

  const getDynamicClass = (pass, index) => {
    let dynamicClass =
      'md:h-35 md:w-35 w-1/4 hover:bg-primary hover:text-white border border-l-0 border-gray-500 font-bold md:py-4 py-4 flex justify-center'

    if (isFirstIndex(index)) {
      dynamicClass += ' rounded-l border-r border-l'
    }

    if (isLastIndex(index)) {
      dynamicClass += ' rounded-r border-l-0'
    }

    if (pass.name === currentPass.name) {
      dynamicClass += ' pass-active'
    }

    return dynamicClass
  }

  return (
    <div className="border border-gray-400 border-b-0 p-6">
      <h2 className="text-l font-bold">{title}</h2>
      <span className="text-s text-gray-600">Wähle einen kostenlosen Pass zu Deinem Tarif.</span>
      <div className="pt-6 w-full md:w-5/6">
        <div className="inline-flex w-full max-w-lg">
          {passKeyArray.map((type, index) => (
            <button
              className={getDynamicClass({ type, ...passData[type] }, index)}
              key={type}
              onClick={() => handleOnClick(passData[type])}
            >
              {getIcon(type)}
            </button>
          ))}
        </div>
        <div className="pt-6 w-full md:w-5/6">
          <span className="font-bold text-l mb-3">{currentPass.title}</span>
          <p className="text-s text-gray-600">{currentPass.description}</p>
        </div>
      </div>
    </div>
  )
}

export default PassSection
