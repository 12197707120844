import React, { Component } from 'react'
import LegalSection from './LegalSection'
import SectionTitle from './SectionTitle'
import ChevronDown from '../icons/ChevronDown'
import { CountryList } from './Constants'
import PropTypes from 'prop-types'
import axios from 'axios'
import FormData from 'form-data'
import { navigate } from 'gatsby'
import BirthdayInput from './BirthdayInput'
import Cookies from 'universal-cookie';

class AddressForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gender: 'mrs',
      firstName: '',
      lastName: '',
      postalCode: '',
      city: '',
      street: '',
      streetNumber: '',
      phone: '',
      email: '',
      nationality: '',
      bankAccountHolder: '',
      bankIban: '',
      birthday: '',
      takeOverNumber: false,
      rateType: props.rate?.title,
      errors: {},
    }

    this.voucherValue = props.rate?.promotion_voucher_value;
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const errors = this.state.errors
    const hasErrors = Object.values(errors).length > 0
    if (hasErrors) return

    const formData = new FormData()

    for (let propertyName in this.state) {
      if (propertyName === 'errors') continue
      const formFieldName = `order[${propertyName}]`
      formData.append(formFieldName, this.state[propertyName])
    }
    formData.append('order[passType]', this.props.currentPass.name)
    formData.append('order[special]', 'unidays nextbike')
    formData.append('order[giftcard]', 'Amazon - ' + this.voucherValue + ' EUR')
    if (formData) this.postRequest(formData)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.getAttribute('data-name')

    this.setState({
      [name]: value,
    })
  }

  async postRequest(data) {
    const url = `${process.env.GATSBY_API_URL}/order`
    const config = {
      method: 'post',
      url: url,
      headers: {
        ...data.getHeaders,
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        let cookies = new Cookies();
        if (response.status === 200) {
          cookies.set('orderHash', response.data.data.id, { path: '/' });
          navigate('/thankyou')
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors?.children
        if (typeof errors !== 'object') return
        const formattedErrors = Object.entries(errors).reduce(
          (formattedErrors, [key, value]) => ({
            ...formattedErrors,
            ...(!Array.isArray(value) ? { [key]: value?.errors?.[0] } : {}),
          }),
          {}
        )
        this.setState({ errors: formattedErrors })
      })
  }

  render() {
    return (
      <div className="border border-gray-400 p-6">
        <SectionTitle title="Deine Daten" />
        <span className="text-sm">
          Gib uns bitte Deine Daten so, wie sie in Deinem Personalausweis oder Reisepass stehen.
        </span>
        <form className="w-full mt-6" onSubmit={this.handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6 w-full md:w-5/6">
            <div className="w-full px-3 mb-3">
              <input
                required
                className="mr-2"
                type="radio"
                id="mrs"
                name="gender"
                data-name="gender"
                onChange={this.handleInputChange}
                value="mrs"
                checked={this.state.gender === 'mrs'}
              />
              <label className="mr-5" htmlFor="mrs">
                Frau
              </label>
              <input
                required
                className="mr-2"
                type="radio"
                id="mr"
                data-name="gender"
                name="gender"
                value="mr"
                checked={this.state.gender === 'mr'}
                onChange={this.handleInputChange}
              />
              <label className="mr-5" htmlFor="mr">
                Herr
              </label>
            </div>
            <div className="w-full px-3 mb-3">
              <label className="block text-gray-700 text-xs font-bold" htmlFor="firstName">
                Vorname
              </label>
              <input
                required
                className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                id="firstName"
                data-name="firstName"
                name="firstName"
                placeholder="Vorname"
                onChange={this.handleInputChange}
                value={this.state.firstName}
              />
            </div>
            <div className="w-full px-3 mb-3">
              <label className="block text-gray-700 text-xs font-bold my-2" htmlFor="lastName">
                Nachname
              </label>
              <input
                required
                className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                id="lastName"
                data-name="lastName"
                name="lastName"
                placeholder="Nachname"
                onChange={this.handleInputChange}
                value={this.state.lastName}
              />
            </div>
            <div className="w-full flex flex-wrap mb-3">
              <div className="w-full md:w-1/3 px-3">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                  htmlFor="postalcode"
                >
                  PLZ
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="postalCode"
                  type="number"
                  data-name="postalCode"
                  name="postalCode"
                  placeholder="PLZ"
                  onChange={this.handleInputChange}
                  value={this.state.postalCode}
                />
              </div>

              <div className="w-full md:w-2/3 px-3">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                  htmlFor="city"
                >
                  Wohnort
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="city"
                  data-name="city"
                  name="city"
                  placeholder="Wohnort"
                  onChange={this.handleInputChange}
                  value={this.state.city}
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap mb-3">
              <div className="w-full md:w-2/3 px-3">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                  htmlFor="street"
                >
                  Straße
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="street"
                  data-name="street"
                  name="street"
                  placeholder="Straße"
                  onChange={this.handleInputChange}
                  value={this.state.street}
                />
              </div>

              <div className="w-full md:w-1/3 px-3">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                  htmlFor="street-number"
                >
                  Hausnummer
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="street-number"
                  data-name="streetNumber"
                  name="streetNumber"
                  placeholder="Hausnr."
                  onChange={this.handleInputChange}
                  value={this.state.streetNumber}
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap mb-6 px-3">
              <label
                  className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                  htmlFor="phone"
              >
                Telefonnummer (Bitte nur mobile Nummer eintragen)
              </label>
              <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="phone"
                  type="text"
                  data-name="phone"
                  name="phone"
                  placeholder="Mobile Rufnummer"
                  onChange={this.handleInputChange}
                  onBlur={() => {
                    if (!this.state.errors || !this.state.errors.phone) return
                    const { phone, ...newErrors } = this.state.errors
                    this.setState({ errors: newErrors })
                  }}
                  value={this.state.phone}
              />
              {this.state.errors?.phone && (
                  <p className="text-primary mt-2">Bitte überpfüfen Sie Ihre Telefonnummer</p>
              )}
            </div>
            <div className="w-full flex flex-wrap mb-6 px-3">
              <label
                className="block tracking-wide text-gray-700 text-xs font-bold my-2"
                htmlFor="street"
              >
                E-Mail-Adresse
              </label>
              <input
                required
                className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                id="email"
                type="email"
                data-name="email"
                name="email"
                placeholder="Deine E-Mail-Adresse"
                onChange={this.handleInputChange}
                onBlur={() => {
                  if (!this.state.errors || !this.state.errors.email) return
                  const { email, ...newErrors } = this.state.errors
                  this.setState({ errors: newErrors })
                }}
                value={this.state.email}
              />
              {this.state.errors?.email && (
                <p className="text-primary mt-2">Bitte überpfüfen Sie Ihre E-Mail-Adresse</p>
              )}
            </div>

            <BirthdayInput
              handleBirthdayChange={(val) => this.setState({ birthday: val })}
              setErrors={(error) => {
                if (!this.state.errors.birthday) {
                  this.setState({ errors: { ...this.state.errrors, ...(error ? error : {}) } })
                } else {
                  const { birthday, ...newErrors } = this.state.errors
                  this.setState({ errors: { ...newErrors, ...(error ? error : {}) } })
                }
              }}
            />

            <div className="w-full flex flex-wrap mb-3">
              <div className="px-3">
                <label className="block tracking-wide text-gray-700 text-xs font-bold my-2">
                  Staatsangehörigkeit
                </label>
              </div>
              <div className="relative w-full px-3">
                <select
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  required
                  onChange={this.handleInputChange}
                  value={
                    this.state.nationality === 'Deine Nationalität wählen'
                      ? ''
                      : this.state.nationality
                  }
                  data-name="nationality"
                  name="nationality"
                  id="nationality"
                >
                  <option disabled value="">
                    Deine Nationalität wählen
                  </option>
                  {CountryList.map((country, index) => (
                    <option key={index} value={country.alpha2}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 text-gray-700">
                  <ChevronDown />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-3 w-full border-t">
            <div className="w-full sm:w-5/6 mt-6">
              <SectionTitle title="Deine Zahlungsart*" />
              <div className="w-full my-3">
                <label
                  className="block text-gray-700 text-xs font-bold my-2"
                  htmlFor="account-holder"
                >
                  Kontoinhaber*
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="account-holder"
                  data-name="bankAccountHolder"
                  name="bankAccountHolder"
                  placeholder="Kontoinhaber/-in"
                  onChange={this.handleInputChange}
                  value={this.state.bankAccountHolder}
                />
              </div>
              <div className="w-full mb-3">
                <label className="block text-gray-700 text-xs font-bold my-2" htmlFor="iban">
                  IBAN*
                </label>
                <input
                  required
                  className="appearance-none block w-full text-gray-700 border border-gray-700 py-2 px-2 leading-tight"
                  id="accountNumber"
                  data-name="bankIban"
                  name="bankIban"
                  placeholder="IBAN"
                  onChange={this.handleInputChange}
                  value={this.state.bankIban}
                  onBlur={() => {
                    if (!this.state.errors || !this.state.errors.bankIban) return
                    const { bankIban, ...newErrors } = this.state.errors
                    this.setState({ errors: newErrors })
                  }}
                />
                {this.state.errors?.bankIban && (
                  <p className="text-primary mt-2">Bitte überpfüfen Sie den angegebenen IBAN!</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-3 w-full border-t">
            <div className="w-full sm:w-5/6 mt-6">
              <label className="block text-gray-500 font-bold">
                <input
                  className="mr-2 leading-tight"
                  id="takeOverNumber"
                  data-name="takeOverNumber"
                  name="takeOverNumber"
                  type="checkbox"
                  onChange={this.handleInputChange}
                  value={this.state.takeOverNumber}
                />
                <span className="text-xl text-gray-900 font-bold">
                  Aktuelle Rufnummer mitnehmen (kostenlos)
                  <br />
                  <span className="text-sm text-gray-400">
                    Alle Infos dazu bekommst Du per E-Mail, sobald Du die Ware hast
                  </span>
                  <br />
                  <p className="text-sm text-gray-700 font-normal">
                    Sie sind bereits Vodafone Kunde? Dann überprüfen Sie <a className="font-bold" href="https://www.vodafone.de/hilfe/alles-zur-rufnummern-mitnahme.html" target="_blank">hier</a> ob Sie Ihre Rufnummer mitnehmen können.
                  </p>
                </span>
              </label>
            </div>
          </div>
          <LegalSection />
          <div className="flex flex-wrap w-full border-t pt-6">
            <button
              className="w-full bg-primary hover:bg-primary-700 text-white font-bold py-2 px-2"
              type="submit"
            >
              Kostenplichtig bestellen
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default AddressForm
