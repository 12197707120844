import React from 'react'

export default ({ width = 50, height = 50, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height} viewBox="0 0 100 100">
      <polyline
        points="117,22 38.8,100.2 4,65.5 "
        stroke="#318721"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        fill="none"
      />
    </svg>
  )
}
