import React from 'react'

const LegalSection = ({ title = 'Allgemeine Vertragsbedingungen und Widerrufsrecht' }) => {
  return (
    <div className="flex flex-wrap mb-6 w-full border-t">
      <div className="w-full sm:w-5/6 mt-6">
        <h2 className="text-l font-bold mb-3">{title}</h2>
        <p className="text-sm text-gray-700 mb-3">
          Bestandteil Deines Vertrages sind die Allgemeinen Geschäftsbedingungen für
          Vodafone-Services, die Preisliste und die Datenschutzerklärung.
        </p>
        <p className="text-sm text-gray-700 mb-3">
          Du hast ein 14-tägiges Widerrufsrecht. Mehr Infos: Einzelheiten zu Deinem Widerrufsrecht.
        </p>
        <h2 className="text-l font-bold mb-3">Bonitätsprüfung</h2>
        <p className="text-sm text-gray-700 mb-3">
          Vodafone tauscht Deine personenbezogenen Daten zur Durchführung von Bonitäsprüfungen
          ("Bonitätsdaten") vor Auftragsannahme mit der Schufa GmbH sowie mit sonstigen Auskunfteien
          gem. Ziff. 7 der Datenschutz-Hinweise aus.
        </p>
      </div>
    </div>
  )
}

export default LegalSection
