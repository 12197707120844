import React from 'react'
import DefaultLayout from '../layouts/defaultLayout'
import MainSection from '../components/Order/MainSection'

export default function Home() {
  return (
    <DefaultLayout>
      <MainSection />
    </DefaultLayout>
  )
}
